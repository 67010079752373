import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { IMaskModule } from 'angular-imask';
import { NgClickOutsideModule } from 'ng-click-outside2';
import { NgSelectModule } from '@ng-select/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { PipesModule } from './pipes';
import { SidebarService } from './services/sidebar.service';
import { PermissionService } from './services/permission.service';
import { DirtyConfirmationModalComponent } from './components/sidebar/dirty-confirmation-modal.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { InputDateMaskControlComponent } from './components/input-date-mask-control/input-date-mask-control.component';
import { InputImpreciseDateControlComponent } from './components/input-imprecise-date-control/input-imprecise-date-control.component';
import { AutoFocusDirective } from './components/auto-focus.directive';
import { StopPropagationDirective } from './components/stop-propagation.directive';
import { PermissionSelectComponent } from './components/permission-select/permission-select.component';
import { IsSidebarDirtyService } from './services/is-sidebar-dirty.service';
import { InputDatetimeControlComponent } from './components/input-datetime-control/input-datetime-control.component';
import { CsvService } from './services/csv.service';
import { KycQuestionnaireComponent } from './components/kyc-questionnaire/kyc-questionnaire.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CanDeactivateSidebarGuard } from '../person/guards/deactivate-sidebar.guard';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { QuillModule } from 'ngx-quill';
import { BundleExpirationNotificationComponent } from './components/bundle-expiration-notification/bundle-expiration-notification.component';
import { MaterialModule } from '../material.module';

const components = [
  ImageUploadComponent,
  DirtyConfirmationModalComponent,
  SidebarComponent,
  InputDateMaskControlComponent,
  InputImpreciseDateControlComponent,
  InputDatetimeControlComponent,
  PermissionSelectComponent,
  AutoFocusDirective,
  StopPropagationDirective,
  KycQuestionnaireComponent,
  TextEditorComponent,
  BundleExpirationNotificationComponent
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PipesModule,
    NgClickOutsideModule,
    BsDatepickerModule.forRoot(),
    IMaskModule,
    FontAwesomeModule,
    NgSelectModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatStepperModule,
    MatTooltipModule,
    MatIconModule,
    MatSelectModule,
    MaterialModule,
    QuillModule.forRoot(),
  ],
  exports: [PipesModule, FormsModule, ReactiveFormsModule, BsDatepickerModule, FontAwesomeModule, ...components],
  providers: [SidebarService, PermissionService, IsSidebarDirtyService, CsvService, CanDeactivateSidebarGuard],
})
export class SharedModule {}
